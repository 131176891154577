
import { withRouter, Link, Route, HashRouter as Router, Switch } from 'react-router-dom'
import Bundle from './Bundle.jsx'

let index = function () {
  return <Bundle load={() => import('./pages/index')}>
    {(Com) => <Com />}
  </Bundle>;
};
function App(props) {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={index} exact />
        </Switch>
      </Router>
    </>
  );
}

export default App;